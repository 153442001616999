import { useVoucherStore, VoucherType } from '@finn/ua-vehicle';
import { cn, useIntl } from '@finn/ui-utils';

const PartnerVoucherBanner = ({ className }: { className?: string }) => {
  const data = useVoucherStore((state) => state.referralVoucherData);
  const { referrer, image } = data || {};
  const isAbsoluteVoucher = data?.type === VoucherType.ABSOLUTE;
  const intl = useIntl();

  const description = isAbsoluteVoucher
    ? intl.formatMessage('general.cugBanner.description.influencer', {
        referrer: referrer || '-',
        value: intl.formatNumber(data?.value_abs, {
          currency: 'EUR',
          style: 'currency',
          maximumFractionDigits: 0,
        }),
      })
    : intl.formatMessage('general.cugBanner.description.partner', {
        referrer: referrer || '-',
      });

  if (!data?.is_valid) return null;

  return (
    <div
      className={cn(
        'border-pearl bg-snow mt-4 flex items-center gap-3 rounded border border-solid px-4 py-1 md:gap-12',
        className
      )}
    >
      {image?.url ? (
        <img
          src={image.url}
          alt={referrer}
          className={cn('h-16 max-w-[40%] align-middle mix-blend-multiply')}
        />
      ) : null}
      <p className="body-12-semibold md:body-14-semibold py-4">{description}</p>
    </div>
  );
};

export default PartnerVoucherBanner;
